import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, FormControlLabel, Link, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack';
import CustomTextInput from './common/CustomTextInput';
import CustomSelect from './common/CustomSelect';

type RegisterFormProps = {
  handleSubmit: (values: any) => void;
  loading: boolean;
}

export default function RegisterForm({ handleSubmit, loading }: RegisterFormProps) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [commercialTitle, setCommercialTitle] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userTypeId, setUserTypeId] = useState<number>();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      sx={{
        mb: 4,
        width: '100%',
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (password !== confirmPassword) {
            enqueueSnackbar('Şifreler eşleşmiyor', { variant: 'error' });
            return;
          }
          handleSubmit({ firstName, lastName, email, password, confirmPassword, commercialTitle, phoneNumber, userTypeId });
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            m: 2,
          }}
        >
          <CustomTextInput
            required
            label='Ad'
            type='text'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <CustomTextInput
            required
            label='Soyad'
            type='text'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <CustomTextInput
            required
            label='Telefon'
            type='text'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <CustomTextInput
            required
            label='Ünvan'
            type='text'
            value={commercialTitle}
            onChange={(e) => setCommercialTitle(e.target.value)}
          />
          <CustomSelect
            required
            label='Kullanıcı Tipi'
            value={userTypeId ? userTypeId.toString() : ''}
            onSelect={(val: string) => setUserTypeId(+val)}
            items={[
              { value: '3', label: 'Satıcı' },
              { value: '2', label: 'Alıcı' },
            ]}
          />
          <CustomTextInput
            required
            label='Email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <CustomTextInput
            required
            label='Şifre'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <CustomTextInput
            required
            label='Şifre Tekrar'
            type='password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              mt: 2,
            }}>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Kullanıcı sözleşmesini okudum, anladım, kabul ediyorum." />
          </Box>
          <LoadingButton
            sx={{ width: '100%', height: 50, mt: 2 }}
            type='submit'
            variant="contained"
            loading={loading}
          >
            Kaydol
          </LoadingButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              mt: 2,
            }}
          >
            <Link href="/login" underline='none' >
              Hesabım var, giriş yap
            </Link>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import CustomBreadCrumbs from "../components/common/CustomBreadCrumbs";
import MarketFilter from "../components/MarketFilter";
import MarketContent from "../components/MarketContent";
import useSWR, { mutate } from "swr";
import { Cart, Product } from "../utils/types";
import { baseUrl } from "../utils/constants";
import { fetcher } from "../utils/globals";
import { AuthContext } from "../contexts/AuthProvider";
import { addNewItemToCart } from "../services/CartServices";
import { enqueueSnackbar } from 'notistack';

export default function MarketContainer() {

  const { token } = useContext(AuthContext);

  const { data: carts } = useSWR<{ data: Cart }>(
    `${baseUrl}/product-dealer-management/carts`,
    (url: string) => fetcher(url, token)
  );

  const { data: products } = useSWR<{ data: Product[] }>(
    `${baseUrl}/product-dealer-management/products?mainCategoryId=0&subCategoryId=0`,
    (url: string) => fetcher(url, token)
  );

  const handleAddToCart = async (product: Product) => {
    try {
      if (carts?.data?.cartDetail.some((item) => item.productId === product.id)) {
        enqueueSnackbar('Bu ürün zaten sepetinizde bulunuyor', { variant: 'error' });
        return;
      }

      const res = await addNewItemToCart({
        id: product.id,
        quantity: 1,
      }, token);

      if (res.isSuccess) {
        enqueueSnackbar('Ürün sepete eklendi', { variant: 'success' });
        mutate(`${baseUrl}/product-dealer-management/carts`);
      } else {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
      console.log(err);
    }
  }

  return (
    <Box>
      <CustomBreadCrumbs
        items={[{ label: "Ana Sayfa", to: "/" }, { label: "Market" }]}
      />
      <Grid sx={{ mt: 4 }} container spacing={2}>
        <Grid item xs={12} sm={3}>
          <MarketFilter />
        </Grid>
        <Grid item xs={12} sm={9}>
          <MarketContent
            products={products?.data || []}
            handleAddToCart={handleAddToCart}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

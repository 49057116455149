import React, { useEffect, useState } from 'react'
import jwt from "jwt-decode";
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { User } from '../utils/types';
import { log } from 'console';
import { get } from 'http';
import { getUserProfileInfo } from '../services/UserServices';

type AuthContextProps = {
  children: React.ReactNode;
}

export type RoleType = 'bayi' | 'tedarikci'

type AuthStateType = {
  token: string;
  exp?: Date;
  login: (token: string, refreshToken?: string) => void,
  logout: () => void,
  getUserInfo?: (token: string) => Promise<void>,
  user?: User
  refreshToken: string
  initialAuthDone: boolean
  role: RoleType
  setRole: (role: RoleType) => void
}

const initialAuthState: AuthStateType = {
  token: "",
  exp: undefined,
  login: (token: string, refreshToken?: string) => { },
  logout: () => { },
  refreshToken: "",
  initialAuthDone: false,
  user: {},
  role: 'bayi',
  setRole: (role: RoleType) => { },
}

export const AuthContext = React.createContext<AuthStateType>(initialAuthState);

function getExp(token: string) {
  var jsonPayload: { exp: number } = jwt(token);
  return jsonPayload.exp;
};

function getRoles(token: string) {
  var jsonPayload: { Roles: string } = jwt(token);
  return jsonPayload.Roles;
}

export default function AuthProvider({ children }: AuthContextProps) {

  const [token, setToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [initialAuthDone, setInitialAuthDone] = useState(false);
  const [exp, setExp] = useState<Date>();
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState<RoleType>('tedarikci');

  const login = (token: string, refreshTkn?: string) => {
    try {
      setExp(new Date(getExp(token) * 1000));
      setToken(token);
      if (getRoles(token) === 'Dealer') {
        setRole('bayi');
      } else {
        setRole('tedarikci');
      }
      setInitialAuthDone(true);
      refreshTkn && setRefreshToken(refreshTkn);
    } catch (e) {
      logout();
    }
  }

  const logout = () => {
    console.log('logout')
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setExp(undefined);
    setUser(undefined);
    setToken('');
    setRefreshToken('');
    setLoading(false);
  }

  const getUserInfo = async (token: string) => {
    setLoading(true);
    try {
      if (role === 'bayi') {
        const res = await getUserProfileInfo('dealers', token);
        if (res.isSuccess) {
          setUser(res.data);
        }
      } else {
        const res = await getUserProfileInfo('suppliers', token);
        if (res.isSuccess) {
          setUser(res.data);
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (token && (new Date(getExp(token) * 1000) > new Date())) getUserInfo(token);
  }, [token])

  useEffect(() => {
    const tokenData = localStorage.getItem('token') || '';
    const refreshTkn = localStorage.getItem('refreshToken');
    refreshTkn && setRefreshToken(refreshTkn);
    if (tokenData) {
      login(tokenData);
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        exp,
        token,
        login,
        logout,
        user,
        getUserInfo,
        refreshToken,
        initialAuthDone,
        role,
        setRole,
      }}>
      {
        loading ?
          <Box
            sx={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CircularProgress />
          </Box> :
          children
      }
    </AuthContext.Provider>
  );
}

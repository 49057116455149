import { Box } from '@mui/material'
import React from 'react'
import ContactContainer from '../containers/ContactContainer'

export default function Contact() {
  return (
    <Box>
      <ContactContainer />
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import OrdersContainer from '../containers/OrdersContainer'

export default function Orders() {
  return (
    <Box>
      <OrdersContainer />
    </Box>
  )
}

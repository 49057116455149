import { LoadingButton } from '@mui/lab'
import { Box, Paper, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import LoginForm from '../components/LoginForm'
import RegisterForm from '../components/RegisterForm';
import { loginUser, registerUser } from '../services/AuthServices';
import { AuthContext } from '../contexts/AuthProvider';
import { enqueueSnackbar } from 'notistack';

export default function Register() {
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);

  const fakeLogin = async (email: string, password: string) => {
    try {
      setLoading(true);
      const res = await loginUser(email.trim(), password.trim());
      console.log(res)
      if (res.isSuccess) {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('refreshToken', res.data.refreshToken)
        login && login(res.data.token);
        enqueueSnackbar('Başarıyla giriş yapıldı', { variant: 'success' });
      } else {
        enqueueSnackbar(res.message, { variant: 'error' });
        console.log(res);
      }
    } catch (err: any) {
      enqueueSnackbar('Bir hata oluştuu', { variant: 'error' });
      console.log(err);
    } finally {
      setLoading(false)
    }
  }


  const handleSubmit = async (values: any) => {
    console.log(values)
    try {
      setLoading(true);
      const res = await registerUser(values);
      fakeLogin(values.email, values.password);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 500,
          width: '100%',
          m: 2,
          mt: 4
        }}
      >
        <h1>Kaydol</h1>
        <RegisterForm handleSubmit={handleSubmit} loading={loading} />
      </Paper>
    </Box>
  )
}

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Badge,
  Box,
  IconButton,
  useTheme
} from "@mui/material";
import React, { useState } from "react";
import { Cart } from "../utils/types";
import ShoppingCartMenu from "./ShoppingCartMenu";

interface ShoppingButtonProps {
  cart?: Cart;
}

export default function ShoppingButton({ cart }: ShoppingButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        mr: 2,
      }}
    >
      <IconButton onClick={handleClick}>
        <Badge
          badgeContent={cart?.cartDetail.length}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ShoppingCartIcon sx={{ color: theme.palette.primary.main }} />
        </Badge>
      </IconButton>
      <ShoppingCartMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        cart={cart}
      />
    </Box>
  );
}

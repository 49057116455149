import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import { AppBar, Box, Button, ButtonGroup, Toolbar } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  marginRight: theme.spacing(2),
  marginLeft: -1,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const IconButtonWrapper = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: theme.spacing(1),
  "&:active": {
    transform: "scale(0.8)",
  },
  border: "none",
}));


export default function OrdersTableAppBar() {
  return (
    <Box mt={2}>
      <AppBar
        sx={{ paddingRight: '0px !important', position: 'relative', borderRadius: 2, backgroundColor: "rgba(88, 108, 216, 1)" }}
      >
        <Toolbar>
          <Search sx={{ background: "rgba(255, 255, 255, 1)", width: 250 }}>
            <SearchIconWrapper sx={{ color: "black" }}>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Ara"
              inputProps={{ "aria-label": "search" }}
              sx={{ width: 300, color: "black" }}
              onChange={() => { }}
            />
          </Search>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
          </Box>
          <ButtonGroup color="inherit" sx={{ border: "none" }}>
            <IconButtonWrapper aria-label="filter" color="inherit">
              <FilterListIcon />
            </IconButtonWrapper>
            <IconButtonWrapper aria-label="export" color="inherit">
              <ImportExportIcon />
            </IconButtonWrapper>
            <IconButtonWrapper aria-label="more" color="inherit">
              <MoreHorizIcon />
            </IconButtonWrapper>
          </ButtonGroup>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

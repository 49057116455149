import { Box, Typography } from "@mui/material";
import SocialButton from "./SocialButton";
import TextButton from "./TextButton";


export default function ContactDetails() {
  return (
    <Box
      sx={{
        backgroundColor: "rgba(88, 108, 216, 1)",
        maxWidth: 491,
        height: 681,
        borderRadius: 3,
        display: "flex",
      }}
    >
      <Box sx={{ marginTop: "60px" }}>
      <Box
        sx={{
          mb: 8,
          ml: 9,
        }}
      >
        <Typography
          sx={{
            maxWidth: 300,
            height: 42,
            fontWeight: 600,
            fontSize: "28px",
            lineHeight: "42px",
            color: "rgba(255,255,255,1)",
            mb: 2,
          }}
        >
          Bizimle iletisime geç!
        </Typography>
        <Typography
          sx={{
            maxWidth: 404,
            height: 27,
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "27px",
            color: "rgba(201,201,201,1)",
          }}
        >
          Formu doldurarak bizimle iletişim kurabilirsin
        </Typography>
      </Box>

      <TextButton />
      <Box sx={{ ml: 8 }}>
        <SocialButton />
      </Box>
      </Box>
    </Box>
  );
}

import { Box, Typography, IconButton } from '@mui/material'
import React from 'react'
import RemoveIcon from '@mui/icons-material/Remove';
import { Add } from '@mui/icons-material'

export default function StockCounter(props: {
  value: number
  onChange: (stockCount: number) => void
}) {

  const handleAdd = () => {
    props.onChange(props.value + 1)
  }

  const handleRemove = () => {
    props.onChange(props.value - 1)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2
      }}
    >
      <Typography>
        Stok Adedi
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          border: '1px solid #e0e0e0',
          width: 'fit-content',
          borderRadius: 4,
        }}
      >
        <IconButton
          disabled={props.value === 0}
          onClick={handleRemove}
        >
          <RemoveIcon />
        </IconButton>
        <Typography>
          {props.value}
        </Typography>
        <IconButton
          onClick={handleAdd}
        >
          <Add />
        </IconButton>
      </Box>
    </Box>
  )
}

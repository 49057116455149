import { Box, ListItem, ListItemIcon, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { ReactNode } from 'react'

type DrawerIconProps = {
  label?: string;
  displayLabel?: boolean
  icon: ReactNode;
  pipePlacement?: 'left' | 'right';
  isActive?: boolean;
  iconButton?: boolean;
  onClick?: () => void;
}

function Pipe({ isActive }: { isActive?: boolean }) {
  return (
    <Box
      sx={{
        height: 24,
        width: '2px',
        background: 'white',
        borderRadius: 1,
        visibility: !isActive ? 'hidden' : 'visible'
      }}
    >
    </Box>
  )
}

export default function DrawerIcon({ onClick, iconButton, label, icon, pipePlacement, isActive, displayLabel }: DrawerIconProps) {
  return (
    <>
      {!iconButton ? (
        <ListItem
          button
          onClick={onClick}
        >
          <ListItemIcon
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Pipe
              isActive={isActive}
            />
            {icon}
          </ListItemIcon>
          {displayLabel && <Typography sx={{ color: isActive ? 'white' : grey[400], textAlign: 'start' }}>{label}</Typography>}
        </ListItem >
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {isActive && pipePlacement === 'left' && <Pipe />}
          {icon}
          {isActive && pipePlacement === 'right' && <Pipe />}
        </Box>
      )
      }
    </>
  )
}

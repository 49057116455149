import { Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import avatar from "../assets/user-pic.png";

export default function ProductSellerDetailComponent() {
  return (
    <Box
      sx={{
        mt: 6
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          alignItems: "center",
          mb: 6,
        }}
      >
        <Avatar
          src={avatar}
          sx={{
            width: [120, 227],
            height: [120, 227],
          }}
        />
        <Box
          sx={{
            ml: [0, 5],
            mt: [3, 0],
            textAlign: ["center", "left"],
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
              fontSize: "33px",
              lineHeight: "40px",
              mb: 1,
            }}
          >
            Bayi Firma Ünvanı
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "17px",
              lineHeight: "24px",
              alignItems: "center",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: 408,
          border: 1,
          position: "relative",
          overflow: "hidden",
          borderRadius: 5,
          mt: 3,
          color: "rgba(211, 203, 251, 1)",
        }}
      >
        <iframe
          width="100%"
          height="408"
          frameBorder="0"
          scrolling="no"
          src="https://maps.google.com/maps?width=100%25&amp;height=408&amp;hl=en&amp;q=fidanlik,%20Adakele%20sokak%20no:13%20Ankara+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </Box>
    </Box>
  )
}

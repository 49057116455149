import { Box } from '@mui/material'
import React from 'react'
import MarketContainer from '../containers/MarketContainer'

export default function Market() {
  return (
    <Box>
      <MarketContainer />
    </Box>
  )
}

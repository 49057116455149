import { Box, Grid } from "@mui/material";
import React from "react";
import CampaignsContainer from "../containers/CampaignsContainer";

export default function Campaigns() {
  return (
    <Box>
      <CampaignsContainer />
    </Box>
  );
}

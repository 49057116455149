import { Box, Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import userPic from "../assets/user-pic.png";
import CustomButton from "./common/CustomButton";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";
import { ProductStock } from "../utils/types";

type StockTableRowItemProps = {
  item: ProductStock;
};

export default function StockTableRowItem({ item }: StockTableRowItemProps) {

  const nav = useNavigate();
  const loc = useLocation();
  const { role } = useContext(AuthContext);

  return (
    <TableRow
      sx={{
        "& td": {
          py: 0,
        },
      }}
    >
      <TableCell
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Typography style={{ fontSize: 12 }}>
          #{item.id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography style={{ fontSize: 12 }}>
          {item.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography style={{ fontSize: 12 }}>
          {item.stockQuantity}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          width: 500,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            justifyContent: "flex-end",
            mx: 1,
            py: 0.5,
          }}
        >
          <CustomButton
            label="Detay Görüntüle"
            variant="contained"
            size="small"
            onClick={() => { }}
          />
          <CustomButton
            label="Düzenle"
            variant="contained"
            size="small"
            onClick={() => { }}
          />
          <CustomButton
            label="Sil"
            variant="contained"
            size="small"
            onClick={() => { }}
          />
          {role !== "bayi" ? (
            <CustomButton
              label="Satışta"
              variant="contained"
              onClick={() => { }}
              sx={{
                fontSize: "10px",
                whiteSpace: "nowrap",
              }}
            />
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  );
}

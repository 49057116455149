import { Avatar, Box, useTheme } from '@mui/material'
import userPic from '../assets/user-pic.png';
import React from 'react'
import UploadIcon from '@mui/icons-material/Upload';
import CustomButton from './common/CustomButton';
import CustomTextInput from './common/CustomTextInput';
import { User } from '../utils/types';

type ProfileGeneralInfoProps = {
  user: User;
  onChange: (value: any) => void;
}

export default function ProfileGeneralInfo({ user, onChange }: ProfileGeneralInfoProps) {

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar
        alt="Remy Sharp"
        src={userPic}
        sx={{
          width: 150,
          height: 150,
          margin: '0 auto',
          border: `5px solid ${theme.palette.primary.main}`,
        }}
      />
      <CustomButton
        label='Fotoğraf Yükle'
        preIcon={<UploadIcon />}
        variant='contained'
        onClick={() => { }}
        sx={{
          mt: 2,
          mb: 2,
        }}
      />
      <CustomTextInput
        required
        label='Ad Soyad'
        onChange={(e) => { onChange({ nameSurname: e.target.value }) }}
        value={user.nameSurname || ''}
      />
      <CustomTextInput
        required
        label='Eposta Adresiniz'
        onChange={(e) => { onChange({ email: e.target.value }) }}
        value={user.email || ''}
      />
      <CustomTextInput
        label='Eski Şifre'
        onChange={(e) => { onChange({ currentPassword: e.target.value }) }}
        type='password'
        value={user.currentPassword || ''}
      />
      <CustomTextInput
        label='Yeni Şifre'
        onChange={(e) => { onChange({ newPassword: e.target.value }) }}
        type='password'
        value={user.newPassword || ''}
      />
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import ProductDetailContainer from '../containers/ProductDetailContainer'
import { useParams } from 'react-router-dom';

export default function ProductDetail() {

  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <Box>
      <ProductDetailContainer id={+id!} />
    </Box>
  )
}

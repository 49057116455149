import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Text1 from "./Text1";
import Text2 from "./Text2";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
  },
});

export default function ContactForm() {
  return (
    <Grid
      container
      spacing={4}
      alignItems="flex-start"
      sx={{
        mt: 5,
      }}
    >
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          id="outlined-basic"
          label="İsim"
          variant="outlined"
          sx={{
            maxWidth: 602,
            height: 48,
            margin: "4px 0",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          id="outlined-basic"
          label="Soyisim"
          variant="outlined"
          sx={{
            maxWidth: 602,
            height: 48,
            margin: "4px 0",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          id="outlined-basic"
          label="Email"
          variant="outlined"
          sx={{
            maxWidth: 602,
            height: 48,
            margin: "4px 0",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Text1 />
      </Grid>
      <Grid item xs={12}>
        <Text2 />
      </Grid>
    </Grid>
  );
}

import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  FilledTextFieldProps,
  Grid,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { JSX } from "react/jsx-runtime";
import flag from "../assets/turkey-flag.svg";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
  },
});

const CustomAutocomplete = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
  },
});

export default function Text1() {
  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          id="outlined-basic"
          label="Telefon numarası"
          variant="outlined"
          //add +90 as prefix and divider  and turkey flag
          InputProps={{
            startAdornment: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <span>
                  <img src={flag} alt="turkey flag" />
                </span>
                <span style={{ marginLeft: 8 }}>+90</span>
                <span 
                  style={{
                    borderLeft: "1px solid rgba(211, 203, 251, 1)",
                    height: 24,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                ></span>
              </div>
            ),
          }}
          sx={{
            maxWidth: 602,
            height: 64,
            margin: "4px 0",
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomAutocomplete
          disablePortal
          id="combo-box-demo"
          sx={{ width: 602 }}
          renderInput={(
            params: JSX.IntrinsicAttributes & {
              variant?: TextFieldVariants | undefined;
            } & Omit<
              | FilledTextFieldProps
              | OutlinedTextFieldProps
              | StandardTextFieldProps,
              "variant"
            >
          ) => (
            <TextField
              {...params}
              label="İletişime geçmek istediğiniz konu"
              InputLabelProps={{ style: { fontWeight: "bold" } }}
            />
          )}
          options={[]}
        />
      </Grid>
    </Grid>
  );
}

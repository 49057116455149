import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function MetricItem({ label, count, background, rate }: { label: string, count: string, background: string, rate: string }) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const matches1 = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        borderRadius: '16px',
        p: 2,
        display: 'flex',
        flexDirection: matches ? 'row' : 'column',
        alignItems: matches ? 'center' : 'center',
        gap: 2,
        width: '100%',
        border: '1px solid #D3CBFB',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          fontSize={14}
          color='primary'
        >
          {label}
        </Typography>
        <Typography
          fontWeight={600}
          fontSize={27}
        >
          {count}
        </Typography>
      </Box>
      <Typography>
        {rate}
        <TrendingUpIcon />
      </Typography>
    </Box>
  )
}

export default function SimpleMetricsReports() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      mt={2}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems:'center',
        }}
        container
        spacing={2}
      >
        <Grid item xs={6} sm={6} md={3}>
          <MetricItem
            label="Aylık Alış"
            count="200₺"
            background='#E3F5FF'
            rate="+11.01%"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <MetricItem
            label="Aylık Satış"
            count="367₺"
            background='#E5ECF6'
            rate="+9.15%"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <MetricItem
            label="Aylık Görüntülenme"
            count="3,456"
            background='#E3F5FF'
            rate="+1201%"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <MetricItem
            label="Aktif Siparişler"
            count="235"
            background='#E5ECF6'
            rate="+19.15%"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <MetricItem
            label="Geçmiş Siparişler"
            count="865"
            background='#E3F5FF'
            rate="+1201%"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <MetricItem
            label="Toplam Sipariş"
            count="9k"
            background='#E5ECF6'
            rate="+19.15%"
          />
        </Grid>
      </Grid>
    </Box>
  )
}

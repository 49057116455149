import React, { ChangeEvent, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

export default function ManageProductImages() {
  const [coverImageFile, setCoverImageFile] = useState<File | null>(null);
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  const handleCoverImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCoverImageFile(e.target.files[0]);
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImageFiles([...imageFiles, ...Array.from(e.target.files)]);
    }
  };

  const removeImage = (index: number) => {
    setImageFiles(imageFiles.filter((_, i) => i !== index));
  };

  const removeCoverImage = () => {
    setCoverImageFile(null);
  };

  return (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: 500,
          aspectRatio: '1/1',
          objectFit: 'contain',
          transition: 'opacity 0.3s, transform 0.5s',
          background: coverImageFile ? `url(${URL.createObjectURL(coverImageFile)}) no-repeat center center` : '#f0f0f0',
          backgroundSize: 'cover',
          borderRadius: 6,
          border: '2px dashed rgba(211, 203, 251, 0.5)',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!coverImageFile && (
          <IconButton component="label">
            <AddIcon />
            <input type="file" hidden onChange={handleCoverImageChange} accept="image/*" />
          </IconButton>
        )}
        {coverImageFile && (
          <IconButton onClick={removeCoverImage} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <ClearIcon />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
          maxWidth: 500, overflow: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            width: '0 !important',
            display: 'none !important',
          },

        }}>
        {imageFiles.map((image, index) => (
          <Box
            key={index}
            sx={{
              width: 100,
              height: 100,
              aspectRatio: '1/1',
              objectFit: 'contain',
              transition: 'opacity 0.3s, transform 0.5s',
              background: `url(${URL.createObjectURL(image)}) no-repeat center center`,
              backgroundSize: 'cover',
              borderRadius: 6,
              border: '2px dashed rgba(211, 203, 251, 0.5)',
              position: 'relative',
            }}
          >
            <IconButton onClick={() => removeImage(index)} sx={{ position: 'absolute', top: 8, right: 8 }}>
              <ClearIcon />
            </IconButton>
          </Box>
        ))}
        <Box
          sx={{
            width: 100,
            height: 100,
            aspectRatio: '1/1',
            objectFit: 'contain',
            transition: 'opacity 0.3s, transform 0.5s',
            background: '#f0f0f0',
            borderRadius: 6,
            border: '2px dashed rgba(211, 203, 251, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton component="label">
            <AddIcon />
            <input type="file" hidden onChange={handleImageChange} accept="image/*" multiple />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}




// import { Add } from '@mui/icons-material';
// import { Box } from '@mui/material'
// import React from 'react'

// export default function ManageProductImages() {

//   const [coverImageFile, setCoverImageFile] = React.useState<File>();
//   const [imageFiles, setImageFiles] = React.useState<File[]>([]);

//   return (
//     <Box
//       sx={{
//         p: 3,
//         display: "flex",
//         flexDirection: "column",
//         gap: 2,
//       }}
//     >
//       <Box
//         sx={{
//           width: "100%",
//           maxWidth: 500,
//           aspectRatio: "1/1",
//           objectFit: "contain",
//           transition: "opacity 0.005s, transform 0.5s",
//           background: "#f0f0f0",
//           borderRadius: 6,
//           border: "2px solid rgba(211, 203, 251, 0.5)",
//         }}
//       >

//       </Box>
//       <Box>
//         {imageFiles.map((image, index) => (
//           <Box
//             sx={{
//               width: "100%",
//               maxWidth: 100,
//               aspectRatio: "1/1",
//               objectFit: "contain",
//               transition: "opacity 0.005s, transform 0.5s",
//               background: "#f0f0f0",
//               borderRadius: 6,
//               border: "2px solid rgba(211, 203, 251, 0.5)",
//             }}
//           >
//           </Box>
//         ))}
//         <Box
//           sx={{
//             width: "100%",
//             maxWidth: 100,
//             aspectRatio: "1/1",
//             objectFit: "contain",
//             transition: "opacity 0.005s, transform 0.5s",
//             background: "#f0f0f0",
//             borderRadius: 6,
//             border: "2px solid rgba(211, 203, 251, 0.5)",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center"
//           }}
//         >
//           <Add />
//         </Box>
//       </Box>
//     </Box>
//   )
// }

import { Box } from "@mui/material";
import React from "react";
import CustomBreadCrumbs from "../components/common/CustomBreadCrumbs";
import ProductOwnerDetailComponent from "../components/ProductOwnerDetailComponent";

export default function ProductOwnerDetailContainer() {
  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: "Ana Sayfa", to: "/" },
          { label: "Tedarikçi Rehberi", to: "/suplier-guide" },
          { label: "Tedarikçi Detay" },
        ]}
      />
      <ProductOwnerDetailComponent />
    </Box>
  );
}

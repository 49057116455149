import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { lightTheme } from './theme';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from './components/Layout';
import Home from './views/Home';
import Profile from './views/Profile';
import Market from './views/Market';
import ProductDetail from './views/ProductDetail';
import ProductOwnerDetail from './views/ProductOwnerDetail';
import Subscription from './views/Subscription';
import Contact from './views/Contact';
import SuplierGuide from './views/SuplierGuide';
import Orders from './views/Orders';
import Campaigns from './views/Campaigns';
import Stock from './views/Stock';
import MyCustomers from './views/MyCustomers';
import MySelling from './views/MySelling';
import AuthProvider from './contexts/AuthProvider';
import ProductSellerDetail from './views/ProductSellerDetail';
import Guard from './components/Guard';
import Login from './views/Login';
import Register from './views/Register';
import { SnackbarProvider } from 'notistack';
import ShoppingCart from './views/ShoppingCart';
import AddEditProduct from './views/AddEditProduct';

function App() {

  return (
    <AuthProvider>
      <SnackbarProvider>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Routes>
            <Guard>
              <Layout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/market" element={<Market />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/stock" element={<Stock />} />
                  <Route path="/stock/:id/edit" element={<AddEditProduct />} />
                  <Route path="/stock/new" element={<AddEditProduct />} />
                  {/* <Route path="/my-selling" element={<MySelling />} /> */}
                  <Route path="/my-customers" element={<MyCustomers />} />
                  <Route path="/campaigns" element={<Campaigns />} />
                  {/* <Route path="/subscription" element={<Subscription />} /> */}
                  <Route path="/shopping-cart" element={<ShoppingCart />} />
                  <Route path="/suplier-guide" element={<SuplierGuide />} />
                  <Route path="/customer-guide" element={<SuplierGuide />} />
                  <Route path="/seller-guide" element={<SuplierGuide />} />
                  <Route path="/my-supliers" element={<SuplierGuide />} />
                  <Route path="/my-sellers" element={<SuplierGuide />} />
                  <Route path="/market/:id" element={<ProductDetail />} />
                  <Route
                    path="/suplier-guide/:id"
                    element={<ProductOwnerDetail />}
                  />
                  <Route
                    path="/seller-guide/:id"
                    element={<ProductSellerDetail />}
                  />
                </Routes>
              </Layout>
            </Guard>
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;

import { Box } from '@mui/material'
import React from 'react'
import CustomTextInput from './common/CustomTextInput'
import { ProductProperty } from '../utils/types';

type ProductFutureFormProps = {
  selectedProperties: ProductProperty[];
  setSelectedProperties: React.Dispatch<React.SetStateAction<ProductProperty[]>>;
}

export default function ProductFutureForm({ selectedProperties, setSelectedProperties }: ProductFutureFormProps) {
  return (
    <Box
      sx={{
        mt: 3
      }}
    >
      {
        selectedProperties.map((property, index) => (
          <CustomTextInput
            key={index}
            label={property.name || ''}
            value={property.value || ''}
            onChange={(e) => {
              const newSelectedProperties = [...selectedProperties];
              newSelectedProperties[index].value = e.target.value;
              setSelectedProperties(newSelectedProperties);
            }}
          />
        ))
      }
    </Box>
  )
}

import { Grid, Box, Typography } from '@mui/material';
import userPic from '../assets/user-pic.png';
import CustomButton from './common/CustomButton';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthProvider';

export default function DealerGuideContent() {
  const nav = useNavigate();
  const loc = useLocation();
  const { role } = useContext(AuthContext);
  return (
    <Grid container spacing={2} justifyContent="center">
      {[...Array(6)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box
            sx={{
              borderRadius: "10px",
              border: "1px solid #D3CBFB",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              padding: 2,
              background: "white",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img height={150} src={userPic} alt="user" />
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "28px",
                lineHeight: "40px",
                textAlign: "center",
              }}
            >
              Tedarikçi Firma
            </Typography>
            <CustomButton
              label="Detay Görüntüle"
              variant="contained"
              onClick={() => {
                nav("/suplier-guide/5");
              }}
              sx={{
                fontSize: "10px",
                whiteSpace: "nowrap",
                mb: 3,
              }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

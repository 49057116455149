import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { Cart } from "../utils/types";
import ShoppingCartMenuItem from "./ShoppingCartMenuItem";
import CustomButton from "./common/CustomButton";
import { useNavigate } from "react-router-dom";

interface ShoppingCartMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  cart?: Cart
}
export default function ShoppingCartMenu({
  anchorEl,
  handleClose,
  cart,
}: ShoppingCartMenuProps) {

  const theme = useTheme();
  const nav = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {cart?.cartDetail.map((item) => (
        <ShoppingCartMenuItem
          handleClose={handleClose}
          product={item}
        />
      ))  
      }
      <MenuItem
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            display: "inline-block",
            padding: "7px 17px",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            fontSize: "16px",
            mb: 1,
          }}
        >
          <Typography
            sx={{
              color: "#fff",
            }}
          >
            Toplam Fiyat: 1000₺
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <CustomButton
          label="Sepetim"
          variant="contained"
          preIcon={<ShoppingCartIcon sx={{ color: "#fff", height: 20 }} />}
          onClick={() => {
            nav("shopping-cart");
            handleClose();
          }}
        />
      </MenuItem>
    </Menu>
  );
}

import { Box } from '@mui/material'
import React from 'react'
import StockContainer from '../containers/StockContainer'

export default function Stock() {
  return (
    <Box>
      <StockContainer />
    </Box>
  )
}

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Box, Typography } from "@mui/material";
interface TreeNode {
  id: string;
  label: string;
  children?: TreeNode[];
}
interface TreeFilterProps {
  data: TreeNode[];
}
export default function TreeFilter({data}:TreeFilterProps) {
  const renderTreeItems = (nodes: TreeNode[]) =>
    nodes.map((node) => (
      <TreeItem key={node.id} nodeId={node.id} label={node.label}>
        {Array.isArray(node.children) ? renderTreeItems(node.children) : null}
      </TreeItem>
    ));
  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     alignItems: "flex-start",
    //     gap: 2,
    //     padding: 2,
    //     background: "white",
    //     borderRadius: 2,
    //     border: "1px solid #D3CBFB",
    //     width: "100%",
    //   }}
    // >
    //   <Typography color="primary">Kategoriler</Typography>
    //   <TreeView
    //     aria-label="file system navigator"
    //     defaultCollapseIcon={<ExpandMoreIcon />}
    //     defaultExpandIcon={<ChevronRightIcon />}
    //     disableSelection
    //     disabledItemsFocusable
    //     sx={{ height: 240, flexGrow: 1, width: "100%", overflowY: "auto" }}
    //   >
    //     <TreeItem nodeId="1" label="Protez">
    //       <TreeItem nodeId="2" label="Protez 1" />
    //     </TreeItem>
    //     <TreeItem nodeId="5" label="Ortez">
    //       <TreeItem nodeId="10" label="Ortez 1" />
    //       <TreeItem nodeId="6" label="Ortez 2">
    //         <TreeItem nodeId="8" label="Ortez 3" />
    //       </TreeItem>
    //     </TreeItem>
    //     <TreeItem nodeId="11" label="Alt Ekstremite">
    //       <TreeItem nodeId="12" label="Material 2" />
    //     </TreeItem>
    //     <TreeItem nodeId="13" label="Üst Ekstremite">
    //       <TreeItem nodeId="14" label="Category 2" />
    //     </TreeItem>
    //     <TreeItem nodeId="15" label="Silikon Süspansiyon Ürünler">
    //       <TreeItem nodeId="16" label="Category 2" />
    //     </TreeItem>
    //     <TreeItem nodeId="17" label="Ayaklar">
    //       <TreeItem nodeId="18" label="Ayaklar 2" />
    //     </TreeItem>
    //     <TreeItem nodeId="19" label="Materyaller">
    //       <TreeItem nodeId="20" label="Materyaller 2" />
    //     </TreeItem>
    //     <TreeItem nodeId="21" label="Komponentler">
    //       <TreeItem nodeId="22" label="Komponentler 2" />
    //     </TreeItem>
    //   </TreeView>
    // </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
        padding: 2,
        background: "white",
        borderRadius: 2,
        border: "1px solid #D3CBFB",
        width: "100%",
      }}
    >
      <Typography color="primary">Kategoriler</Typography>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        disableSelection
        disabledItemsFocusable
        sx={{ height: 240, flexGrow: 1, width: "100%", overflowY: "auto" }}
      >
        {renderTreeItems(data)}
      </TreeView>
    </Box>
  );
}

import { Box, Grid } from "@mui/material";
import React from "react";
import CustomBreadCrumbs from "../components/common/CustomBreadCrumbs";
import CampaignItem from "../components/CampaignItem";
import ProductCardItem from "../components/ProductCardItem";

export default function CampaignsContainer() {
  return (
    <Box>
      <CustomBreadCrumbs
        items={[{ label: "Ana Sayfa", to: "/" }, { label: "Kampanyalar" }]}
      />
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          justifyContent: "center",
        }}
      >
        {Array.from(Array(3).keys()).map((item, index) => (
          <CampaignItem isComposing={true} key={index} />
        ))}
      </Box> */}
      <Box>
        <Grid container spacing={2}>
          {Array.from(Array(8).keys()).map((item, index) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <ProductCardItem isCampaign={true} key={index} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

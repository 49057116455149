import { Box, Grid } from '@mui/material'
import React from 'react'
import CustomTextInput from './common/CustomTextInput'
import ProductCardItem from './ProductCardItem'
import { Product } from '../utils/types'

type MarketContentProps = {
  products: Product[];
  handleAddToCart?: (product: Product) => void;
}

export default function MarketContent({ products,handleAddToCart }: MarketContentProps) {
  return (
    <Box>
      <CustomTextInput
        label='Ürün Ara'
        value=''
        onChange={() => { }}
        sx={{
          mt: 0
        }}
      />
      <Box>
        <Grid container spacing={2}>
          {products.map((product, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
              <ProductCardItem
                product={product}
                handleAddToCart={handleAddToCart}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

import { Box } from '@mui/material'
import React from 'react'
import ShoppingCartContainer from '../containers/ShoppingCartContainer'

export default function ShoppingCart() {
  return (
    <Box>
      <ShoppingCartContainer />
    </Box>
  )
}

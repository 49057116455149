import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    }
  },
  typography: {
    fontFamily: 'Poppins, Arial',
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#586CD8'
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text:{
      primary: '#000000',
    }
  },
});

import { Box } from '@mui/material';import TreeFilter from './TreeFilter';
import { AuthContext } from '../contexts/AuthProvider';
import React, { useContext } from 'react';
interface TreeNode {
  id: string;
  label: string;
  children?: TreeNode[];
}

export default function MarketFilter() {
  const { role } = useContext(AuthContext);
  const treeData1: TreeNode[] = [
    {
      id: "1",
      label: "Protez",
      children: [{ id: "2", label: "Protez 1" }],
    },
    {
      id: "5",
      label: "Ortez",
      children: [{ id: "10", label: "Ortez 1" }],
      
    },
    {
      id: "11",
      label: "Alt Ekstremite",
      children: [{ id: "12", label: "Alt Ekstremite 1" }],
    },
    {
      id: "13",
      label: "Üst Ekstremite",
      children: [{ id: "14", label: "Üst Ekstremite 1" }],
    },
    {
      id: "15",
      label: "Silikon Süspansiyon Ürünler",
      children: [{ id: "16", label: "Silikon Süspansiyon Ürünler 1" }],
    },
    {
      id: "17",
      label: "Ayaklar",
      children: [{ id: "18", label: "Ayaklar 1" }],
    },
    {
      id: "19",
      label: "Materyaller",
      children: [{ id: "20", label: "Materyaller 1" }],
    },
    {
      id: "21",
      label: "Komponentler",
      children: [{ id: "22", label: "Komponentler 1" }],
    },
  ];
  const treeData2: TreeNode[] = [
    {
      id: "1",
      label: "Protez",
      children: [{ id: "2", label: "Protez 1" }],
    },
    {
      id: "5",
      label: "Ortez",
      children: [{ id: "10", label: "Ortez 1" }],
      
    },
    {
      id: "11",
      label: "Alt Ekstremite",
      children: [{ id: "12", label: "Alt Ekstremite 1" }],
    },
    {
      id: "13",
      label: "Liner",
      children: [{ id: "14", label: "Liner 1" }],
    },
    {
      id: "15",
      label: "Dizlik",
      children: [{ id: "16", label: "Dizlik 1" }],
      
    },
    {
      id: "17",
      label: "Ayaklar",
      children: [{ id: "18", label: "Ayaklar 1" }],
    },
    {
      id: "19",
      label: "Eklemler",
      children: [{ id: "20", label: "Eklemler 1" }],
    },
    {
      id: "21",
      label: "Kompozitler",
      children: [{ id: "22", label: "Kompozitler 1" }],
      
    },
    {
      id: "23",
      label: "Üst Ekstremite",
      children: [{ id: "24", label: "Üst Ekstremite 1" }],
    },
    {
      id: "25",
      label: "Omuz-Kol",
      children: [{ id: "26", label: "Omuz-Kol 1" }],
    },
    {
      id: "27",
      label: "Dirsek Altı",
      children: [{ id: "28", label: "Dirsek Altı 1" }],
    },
    {
      id: "29",
      label: "Dirsek Üstü",
      children: [{ id: "30", label: "Dirsek Üstü 1" }],
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      {role === "bayi" ? <TreeFilter data={treeData1} /> :  <TreeFilter data={treeData2} />}
      {/* <TreeFilter /> */}
      {/* <PriceRangeFilter />
      <CustomTextInput
        label="Filtre Kelimelerini Giriniz"
        value=""
        multiline
        rows={4}
        onChange={() => { }}
      /> */}
    </Box>
  );
}

import React, { useState } from "react";
import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import CustomButton from "./common/CustomButton";
import { useNavigate } from "react-router-dom";
import productPic from "../assets/product-img.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { CartDetail } from "../utils/types";

interface ShoppingCartMenuItemProps {
  handleClose: () => void;
  product?: CartDetail;
}
export default function ShoppingCartMenuItem({
  handleClose,
  product
}: ShoppingCartMenuItemProps) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          p: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Sepet Detayları
        </Typography>
      </Box>
      <MenuItem sx={{ "&:hover": { backgroundColor: "transparent" } }}>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <img
            src={productPic}
            alt=""
            style={{
              height: 135,
              width: 135,
              border: "1px solid #D3CBFB",
            }}
          />
          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                mb: 3,
              }}
            >
              {product?.productName}
            </Typography>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                display: "inline-block",
                padding: "7px 17px",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                fontSize: "16px",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                }}
              >
                {product?.quantity} adet {product?.totalPrice}₺
              </Typography>
            </Box>
          </Box>
        </Box>
      </MenuItem>
    </>
  );
}

import { Box } from '@mui/material'
import React from 'react'
import AddEditProductContainer from '../containers/AddEditProductContainer'

export default function AddEditProduct() {
  return (
    <Box>
      <AddEditProductContainer />
    </Box>
  )
}

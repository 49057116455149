import { Box } from '@mui/material'
import React from 'react'
import ProductSellerDetailContainer from '../containers/ProductSellerDetailContainer'

export default function ProductSellerDetail() {
  return (
    <Box>
      <ProductSellerDetailContainer />
    </Box>
  )
}

import { LoadingButton } from '@mui/lab'
import { Box, Paper, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthProvider';
import LoginForm from '../components/LoginForm';
import { loginUser } from '../services/AuthServices';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);

  const handleSubmit = async (email: string, password: string) => {
    try {
      setLoading(true);
      const res = await loginUser(email, password);
      localStorage.setItem('token', res.data.token);
      login(res.data.token);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      // showAlert(error.message, 'error');
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'calc(100vh - 64px)',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 500,
          width: '100%',
          m: 2,
        }}
      >
        <h1>Giriş</h1>
        <LoginForm handleSubmit={handleSubmit} loading={loading} />
      </Paper>
    </Box>
  )
}

import { Badge, Box, Divider, Rating, Typography } from "@mui/material";
import productPic from "../assets/product-img.png";
import React, { useContext } from "react";
import CustomButton from "./common/CustomButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthProvider";
import { Product } from "../utils/types";
interface CampaignProps {
  product?: Product;
  isCampaign?: boolean;
  handleAddToCart?: (product: Product) => void;
}
export default function ProductCardItem({ product, isCampaign, handleAddToCart }: CampaignProps) {

  const { role } = useContext(AuthContext);
  const nav = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
        py: 2,
        px: 1,
        background: "white",
        borderRadius: 2,
        border: "1px solid #D3CBFB",
        width: "100%",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #D3CBFB",
          position: "relative",
          width: "100%",
        }}
      >
        {isCampaign && (
          <Badge
            sx={{
              position: "absolute",
              backgroundColor: "rgba(211, 203, 251, 1)",
              color: "white",
              fontSize: "10px",
              padding: "4px 8px",
              borderRadius: "50%",
              width: 57,
              height: 57,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "7px",
                lineHeight: "14px",
                textAlign: "center",
                color: "black",
                mt: 1.5,
              }}
            >
              Kampanyalı Ürün
            </Typography>
          </Badge>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            right: 0,
            top: -8,
            gap: 1,
            position: "absolute",
          }}
        >
          <Rating
            name="read-only"
            value={3}
            size="small"
            getLabelText={(value: number) =>
              `${value} Heart${value !== 1 ? "s" : ""}`
            }
            readOnly
          />
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            3.0
          </Typography>
        </Box>
        <img width="100%" src={productPic} alt="product" />
      </Box>
      <Box>
        <Typography>
          {product?.name}
        </Typography>
        <Typography
          sx={{
            color: "text.secondary",
          }}
        >
          Firma İsmi
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: 1,
        }}
      >
        <CustomButton
          label="Detaylı Bilgi"
          preIcon={<InfoOutlinedIcon sx={{ height: 20, width: 20 }} />}
          onClick={() => {
            nav(`/market/${product?.id}`);
          }}
          sx={{
            fontSize: "10px",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        />

        {role === 'bayi' && (
          <CustomButton
            label="Sepete Ekle"
            variant="contained"
            preIcon={
              <LocalGroceryStoreOutlinedIcon sx={{ height: 20, width: 20 }} />
            }
            onClick={() => { handleAddToCart && handleAddToCart(product as Product)}}
            sx={{
              fontSize: "10px",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          />)}
      </Box>
    </Box>
  );
}

import { Box, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import OrdersTableRowItem from './OrdersTableRowItem';
import StockTableRowItem from './StockTableRowItem';
import { ProductStock } from '../utils/types';

type StockTableProps = {
  stock: ProductStock[];
}

export default function StockTable({ stock }: StockTableProps) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
          background: 'white',
          borderRadius: 2,
          border: '1px solid #D3CBFB',
          width: '100%'
        }}
      >
        <TableContainer
          style={{
            overflowX: 'initial',
          }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead
              sx={{
                '& th': {
                  backgroundColor: 'transparent !important'
                }
              }}
            >
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontSize: 12 }}>Ürün Id</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: 12 }}>Ürün İsmi</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: 12 }}>Ürün Adedi</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography sx={{ fontSize: 12 }}>İşlemler</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                stock.map((item, index) => (
                  <StockTableRowItem
                    key={index}
                    item={item}
                  />
                ))
              }
            </TableBody>
          </Table>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 10,
              width: '100%',
            }}
          >
          </Box>
        </TableContainer>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mt: 1
        }}
      >
        <Pagination count={10} size="small" />
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import SimpleMetricsReports from '../components/SimpleMetricsReports'
import Currencies from '../components/Currencies'

export default function HomeContainer() {
  return (
    <Box>
      <Currencies />
      <SimpleMetricsReports />
    </Box >
  )
}

import { Box } from "@mui/material";
import React from "react";
import CustomBreadCrumbs from "../components/common/CustomBreadCrumbs";
import ProductSellerDetailComponent from "../components/ProductSellerDetailComponent";

export default function ProductSellerDetailContainer() {
  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: "Ana Sayfa", to: "/" },
          { label: "Bayi Rehberi", to: "/suplier-guide" },
          { label: "Bayi Detay" },
        ]}
      />
      <ProductSellerDetailComponent />
    </Box>
  );
}

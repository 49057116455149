import { Box } from '@mui/material'
import React from 'react'
import CustomBreadCrumbs from '../components/common/CustomBreadCrumbs'
import ContactDetails from '../components/ContactDetails'
import ContactForm from '../components/ContactForm'

export default function ContactContainer() {
  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'İletişim' },
        ]}
      />
      <Box
        sx={{
          maxWidth: 1129,
          height: 705,
          borderRadius: 5,
          border: "1px solid rgba(211,203,251,1)",
          margin: "auto",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          mt: 4,
        }}
      >
        <Box sx={{ flex: 3, mt: 2, ml: 1 }}>
          <ContactDetails />
        </Box>
        <Box sx={{ flex: 3.7, mt: -4 }}>
          <ContactForm />
        </Box>
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import ProductOwnerDetailContainer from '../containers/ProductOwnerDetailContainer'

export default function ProductOwnerDetail() {
  return (
    <Box>
      <ProductOwnerDetailContainer />
    </Box>
  )
}

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import DrawerIcon from './common/DrawerIcon';
import { ReactComponent as HomeIcon } from '../assets/drawerIcons/Vector.svg';
import { ReactComponent as ProfileIcon } from '../assets/drawerIcons/Vector-1.svg';
import { ReactComponent as SupplierIcon } from '../assets/drawerIcons/Vector-2.svg';
import { ReactComponent as SupplierGuideIcon } from '../assets/drawerIcons/Vector-11.svg';
import { ReactComponent as CustomerIcon } from '../assets/drawerIcons/Vector-3.svg';
import { ReactComponent as MarketIcon } from '../assets/drawerIcons/Vector-4.svg';
import { ReactComponent as OrdersIcon } from '../assets/drawerIcons/Vector-5.svg';
import { ReactComponent as SellingIcon } from '../assets/drawerIcons/Vector-6.svg';
import { ReactComponent as StockIcon } from '../assets/drawerIcons/Vector-7.svg';
import { ReactComponent as SubcribeIcon } from '../assets/drawerIcons/Vector-8.svg';
import { ReactComponent as MiniLogo } from '../assets/drawerIcons/mini-logo.svg';
import { ReactComponent as LargeLogo } from '../assets/logo.svg';
import { ReactComponent as HelpCenterLogo } from '../assets/drawerIcons/Vector-10.svg';
import { Box, List } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthProvider';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.palette.primary.main,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 80,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  backgroundColor: theme.palette.primary.main,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

type MyDrawerProps = {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
};

export default function MyDrawer({
  open,
  handleDrawerClose,
  handleDrawerOpen,
}: MyDrawerProps) {
  const theme = useTheme();
  const nav = useNavigate();
  const loc = useLocation();
  const { role } = useContext(AuthContext);

  const handleToggleDrawer = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          border: 'none',
        },
      }}
      variant='permanent'
      open={open}
    >
      <DrawerHeader
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            mb: 1,
          }}
        >
          <IconButton onClick={handleToggleDrawer}>
            {!open ? (
              <DrawerIcon
                icon={<ArrowForward sx={{ color: 'white', mt: 1 }} />}
                pipePlacement='right'
                isActive
                iconButton
              />
            ) : (
              <DrawerIcon
                icon={<ArrowBack sx={{ color: 'white' }} />}
                pipePlacement='left'
                isActive
                iconButton
              />
            )}
          </IconButton>
        </Box>
        <Box
          sx={{
            height: 60,
          }}
        >
          {open ? (
            <LargeLogo />
          ) : (
            <MiniLogo
              style={{
                height: 60,
                width: 60,
              }}
            />
          )}
        </Box>
      </DrawerHeader>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          height: '100%',
          pb: 5
        }}
      >
        <DrawerIcon
          icon={
            <HomeIcon
              style={{ fill: loc.pathname === '/' ? 'white' : grey[400] }}
            />
          }
          label='Ana Sayfa'
          displayLabel={open}
          isActive={loc.pathname === '/'}
          pipePlacement='left'
          onClick={() => nav('/')}
        />
        <DrawerIcon
          icon={
            <ProfileIcon
              style={{
                fill: loc.pathname === '/profile' ? 'white' : grey[400],
              }}
            />
          }
          label='Profil Bilgileri'
          displayLabel={open}
          pipePlacement='left'
          isActive={loc.pathname === '/profile'}
          onClick={() => nav('/profile')}
        />
        <DrawerIcon
          icon={
            <SupplierGuideIcon
              style={{
                fill: (loc.pathname === '/suplier-guide' || loc.pathname === '/customer-guide') ? 'white' : grey[400],
              }}
            />
          }
          label={role === 'tedarikci' ? 'Bayi Rehberi' : 'Tedarikçi Rehberi'}
          displayLabel={open}
          pipePlacement='left'
          isActive={(loc.pathname === '/suplier-guide' || loc.pathname === '/customer-guide')}
          onClick={() => role === 'tedarikci' ? nav('/customer-guide') : nav('/suplier-guide')}
        />
        {role === 'bayi' && (
          <DrawerIcon
            icon={
              <SupplierIcon
                style={{
                  fill: loc.pathname === '/my-supliers' ? 'white' : grey[400],
                }}
              />
            }
            label={'Kayıtlı Tedarikçilerim'}
            displayLabel={open}
            pipePlacement='left'
            isActive={loc.pathname === '/my-supliers'}
            onClick={() => nav('/my-supliers')}
          />
        )}
        {role === 'tedarikci' && (
          <DrawerIcon
            icon={
              <CustomerIcon
                style={{
                  fill: loc.pathname === '/my-customers' ? 'white' : grey[400],
                }}
              />
            }
            label='Kayıtlı Bayilerim'
            displayLabel={open}
            pipePlacement='left'
            isActive={loc.pathname === '/my-customers'}
            onClick={() => nav('/my-customers')}
          />
        )}
        <DrawerIcon
          icon={
            <MarketIcon
              style={{ fill: loc.pathname === '/market' ? 'white' : grey[400] }}
            />
          }
          label='Market'
          displayLabel={open}
          pipePlacement='left'
          isActive={loc.pathname === '/market'}
          onClick={() => nav('/market')}
        />
        <DrawerIcon
          icon={
            <OrdersIcon
              style={{ fill: loc.pathname === '/orders' ? 'white' : grey[400] }}
            />
          }
          label='Siparişler'
          displayLabel={open}
          pipePlacement='left'
          isActive={loc.pathname === '/orders'}
          onClick={() => nav('/orders')}
        />
        {/* <DrawerIcon
          icon={<SellingIcon style={{ fill: loc.pathname === '/my-selling' ? 'white' : grey[400] }} />}
          label='Satışlar'
          displayLabel={open}
          pipePlacement='left'
          isActive={loc.pathname === '/my-selling'}
          onClick={() => nav('/my-selling')}
        /> */}
        {role === 'tedarikci' && (
        <DrawerIcon
          icon={
            <StockIcon
              style={{ fill: loc.pathname === '/stock' ? 'white' : grey[400] }}
            />
          }
          label='Stok & Katalog'
          displayLabel={open}
          pipePlacement='left'
          isActive={loc.pathname === '/stock'}
          onClick={() => nav('/stock')}
        />
        )}
        {/* <DrawerIcon
          icon={<SubcribeIcon style={{ fill: loc.pathname === '/subscription' ? 'white' : grey[400] }} />}
          label='Abonelikliğim'
          displayLabel={open}
          pipePlacement='left'
          isActive={loc.pathname === '/subscription'}
          onClick={() => nav('/subscription')}
        /> */}
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
        </Box>
        <DrawerIcon
          icon={
            <HelpCenterLogo
              style={{
                fill: loc.pathname === '/contact' ? 'white' : grey[400],
              }}
            />
          }
          label='Yardım Merkezi'
          displayLabel={open}
          pipePlacement='left'
          isActive={loc.pathname === '/contact'}
          onClick={() => nav('/contact')}
        />
      </List>
    </Drawer>
  );
}

import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import CustomBreadCrumbs from "../components/common/CustomBreadCrumbs";
import GuideFilters from "../components/GuideFilters";
import GuideContent from "../components/GuideContent";
import { AuthContext } from "../contexts/AuthProvider";
import TreeFilter from "../components/TreeFilter";
import SuplierGuideContent from "../components/SuplierGuideContent";
import CostumerGuideContent from "../components/CostumerGuideContent";

export default function SuplierGuideContainer() {
  const { role } = useContext(AuthContext);
  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: "Ana Sayfa", to: "/" },
          { label: role === "bayi" ? "Tedarikçi Rehberi" : "Bayi Rehberi" },
        ]}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          mt: 4,
        }}
      >
        {/* <Box
          sx={{
            width: 260
          }}
        >
          <GuideFilters />
        </Box> */}
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {/* <GuideContent/> */}
          {role === "bayi" ? <CostumerGuideContent /> : <SuplierGuideContent />}
        </Box>
      </Box>
    </Box>
  );
}

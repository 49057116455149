import { Box } from '@mui/material'
import React, { useContext } from 'react'
import CustomBreadCrumbs from '../components/common/CustomBreadCrumbs'
import ProductDetailComponent from '../components/ProductDetailComponent'
import { AuthContext } from '../contexts/AuthProvider'
import useSWR, { mutate } from 'swr'
import { Cart, Product } from '../utils/types'
import { baseUrl } from '../utils/constants'
import { fetcher } from '../utils/globals'
import { enqueueSnackbar } from 'notistack'
import { addNewItemToCart } from '../services/CartServices'

type ProductDetailContainerProps = {
  id: number;
}

export default function ProductDetailContainer({ id }: ProductDetailContainerProps) {

  const { token } = useContext(AuthContext);

  const { data: product } = useSWR<{ data: Product }>(
    `${baseUrl}/product-dealer-management/products/${id}`,
    (url: string) => fetcher(url, token)
  );

  const { data: carts } = useSWR<{ data: Cart }>(
    `${baseUrl}/product-dealer-management/carts`,
    (url: string) => fetcher(url, token)
  );

  const handleAddToCart = async (product: Product) => {
    try {
      if (carts?.data?.cartDetail.some((item) => item.productId === id)) {
        enqueueSnackbar('Bu ürün zaten sepetinizde bulunuyor', { variant: 'error' });
        return;
      }

      const res = await addNewItemToCart({
        id,
        quantity: 1,
      }, token);

      if (res.isSuccess) {
        enqueueSnackbar('Ürün sepete eklendi', { variant: 'success' });
        mutate(`${baseUrl}/product-dealer-management/carts`);
      } else {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
      console.log(err);
    }
  }

  if (!product) {
    return null;
  }

  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Market', to: '/market' },
          { label: 'Ürün Detayı' },
        ]}
      />
      <ProductDetailComponent 
        product={product?.data}
        handleAddToCart={handleAddToCart}
      />
    </Box>
  )
}

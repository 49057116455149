import { Box, Checkbox, TableCell, TableRow, Typography } from '@mui/material'
import React, { useContext } from 'react'
import CustomButton from './common/CustomButton'
import userPic from '../assets/user-pic.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthProvider'

export default function GuideTableRowItem(props: {
  isMyCustomers?: boolean
}) {
  const nav = useNavigate();
  const loc = useLocation();
  const { role } = useContext(AuthContext);
  return (
    <TableRow
      sx={{
        '& td': {
          py: 0
        },
      }}
    >
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1
          }}
        >
          <img style={{ height: 24, width: 24 }} src={userPic} alt="user" />
          <Typography style={{ fontSize: 12 }}>Koray Okumuş</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography style={{ fontSize: 12 }}>Sütun 1</Typography>
      </TableCell>
      <TableCell>
        <Typography style={{ fontSize: 12 }}>Sütun 2</Typography>
      </TableCell>
      <TableCell
        sx={{
          width: 350
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            justifyContent: 'flex-end',
            mx: 1,
            py: 0.5
          }}
        >
          {/* <CustomButton
            label={role === 'bayi' ? 'Tedarikçi Profili' : "Protezci Profili"}
            variant='contained'
            size='small'
            onClick={() => { role === 'bayi' ? nav('/suplier-guide/5') : nav('/seller-guide/5') }}
          />
          {(loc.pathname.includes('my-supliers') || loc.pathname.includes('my-sellers')) && (
            <CustomButton
              label='Etkileşim Butonu'
              variant='contained'
              size='small'
              onClick={() => { }}
            />
          )} */}
          <CustomButton
            label='Bayi Profili'
            variant='contained'
            size='small'
            onClick={() => {
              nav('/seller-guide/5');
            }}
          />
          {props.isMyCustomers && (
            <CustomButton
              label='Bayilerimden Kaldır'
              variant='contained'
              size='small'
              onClick={() => {
                nav('/seller-guide/5');
              }}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

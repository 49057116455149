
import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

export const getUserProfileInfo = async (userType: string, token: string) => {
  const url = userType === 'dealers' ? `${baseUrl}/dealer-management/${userType}` : `${baseUrl}/supplier-management/${userType}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);

  return res;
}

export const updateUserProfileInfo = async (userType: string, token: string, data: any) => {
  const url = userType === 'dealers' ? `${baseUrl}/dealer-management/${userType}` : `${baseUrl}/supplier-management/${userType}`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  });

  const res = await handleResponse(response);

  return res;
}
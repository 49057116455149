import { Box, Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import CustomBreadCrumbs from '../components/common/CustomBreadCrumbs'
import ProfileGeneralInfo from '../components/ProfileGeneralInfo'
import ProfileDetailInfo from '../components/ProfileDetailInfo'
import CustomButton from '../components/common/CustomButton'
import { AuthContext } from '../contexts/AuthProvider'
import { updateUserProfileInfo } from '../services/UserServices'
import { enqueueSnackbar } from 'notistack';

export default function ProfileContainer() {

  const { user, token, role } = useContext(AuthContext);

  const [userInfo, setUserInfo] = React.useState(user);

  const onChange = (value: any) => {
    setUserInfo({
      ...userInfo,
      ...value
    });
  }

  const handleSubmit = async () => {
    try {
      const userType = role === 'bayi' ? 'dealers' : 'suppliers';

      const res = await updateUserProfileInfo(userType, token, {
        ...userInfo,
        newPassword: "1",
        currentPassword: "1"
      });
      if (res.isSuccess) {
        enqueueSnackbar('Profil bilgileri başarıyla güncellendi', { variant: 'success' });
      } else {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (err: any) {
      enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
      console.log(err)
    }
  }

  if (!userInfo) return null;
  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Profil Bilgileri' },
        ]}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Grid sx={{ mt: 4 }} container spacing={2}>
          <Grid item xs={12} sm={4}>
            <ProfileGeneralInfo
              user={userInfo}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <ProfileDetailInfo
              user={userInfo}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CustomButton
            label='Kaydet'
            type='submit'
            variant='contained'
            sx={{
              mt: 2,
              mb: 2,
            }}
          />
        </Box>
      </form>
    </Box>
  )
}

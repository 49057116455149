import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { refreshToken as refreshTkn } from '../services/AuthServices'
import { AuthContext } from '../contexts/AuthProvider';

type GuardProps = {
  children: React.ReactNode;
};

const nonAuthRoutes = ['/login', '/register'];

export default function Guard({ children }: GuardProps) {
  const { exp, refreshToken, logout, initialAuthDone, login } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);

  const nav = useNavigate();
  const loc = useLocation();

  const refresh = async () => {
    try {
      setRefreshing(true);
      const res = await refreshTkn(refreshToken);
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('refreshToken', res.data.refreshToken)
      login && login(res.data.token);
      setRefreshing(false);
    } catch (err) {
      console.log(err);
      logout && logout();
      setRefreshing(false);
    }
  }

  useEffect(() => {
    if (!exp && !nonAuthRoutes.includes(loc.pathname)) {
      nav(`/login?redirect=${loc.pathname}&redirectQs=${loc.search.slice(1)}`, { replace: true });
    }

    if (exp && nonAuthRoutes.includes(loc.pathname)) {
      const qs = new URLSearchParams(loc.search);
      let redirectUrl = qs.get('redirect');
      if (redirectUrl) {
        redirectUrl += '?' + qs.get('redirectQs');
      } else {
        redirectUrl = '/';
      }
      nav(redirectUrl, { replace: true });

    }

    if (exp) {
      console.log('exp var')
      if (exp < new Date()) {
        console.log('refresh edilmesi lazım')
        if (refreshToken) {
          console.log('refreshtoken var refresh edilecek')
          refresh();

        } else {
          console.log('refresh token yok')
          logout!();
        }
      }
    } else {
      console.log('exp yok')
      if (!nonAuthRoutes.includes(loc.pathname) && initialAuthDone) {
        console.log('nonroute pathlardan biri değil logoyr edilecek 2')
        nav(`/login?redirect=${loc.pathname}&redirectQs=${loc.search.slice(1)}`, { replace: true });
        logout!();
      }
    }

    // if (exp && exp < new Date() && !nonAuthRoutes.includes(loc.pathname)) {
    //     nav(`/login?redirect=${loc.pathname}&redirectQs=${loc.search.slice(1)}`, { replace: true });
    //     logout!();
    // }

  }, [loc.pathname, exp, refreshToken, initialAuthDone])

  if (refreshing) return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
    </Box>
  )

  if (!exp) return null;

  return <div>{children}</div>;
}

import { Box } from '@mui/material'
import React from 'react'
import CustomBreadCrumbs from '../components/common/CustomBreadCrumbs'
import MyCustomersTableAppBar from '../components/MyCustomersTableAppBar'
import MyCustomersTable from '../components/MyCustomersTable'

export default function MyCustomersContainer() {
  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Kayıtlı Bayilerim' },
        ]}
      />
      <MyCustomersTableAppBar />
      <Box
        sx={{
          mt: 2
        }}
      >
        <MyCustomersTable />
      </Box>
    </Box>
  )
}

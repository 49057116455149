import { Box, SxProps, TextField, Typography } from '@mui/material'
import { type } from 'os';
import React from 'react'

type CustomTextInputProps = {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  autoComplete?: string;
}

export default function CustomTextInput({ label, value, onChange, sx, type = 'text', required, multiline, rows, autoComplete }: CustomTextInputProps) {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <TextField
        required={required}
        multiline={multiline}
        rows={rows}
        label={label}
        autoComplete="new-password"
        variant="outlined"
        value={value}
        type={type}
        onChange={onChange}
        sx={{
          my: 1,
          width: '100%',
          boxShadow: '0px 0px 5px rgba(49, 79, 124, 0.1)',
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#D3CBFB',
            },
            '&:hover fieldset': {
              borderColor: '#D3CBFB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#D3CBFB',
            },
          },
          ...sx,
        }}
      />
    </Box>
  )
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ManageProductInfo from './ManageProductInfo';
import ProductFutureForm from './ProductFutureForm';
import { Product, ProductProperty } from '../utils/types';
import { createProduct } from '../services/ProductServices';
import { AuthContext } from '../contexts/AuthProvider';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const steps = ['Ürün Bilgileri', 'Ürün Özellikleri'];

export default function AddEditProductStepper() {

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [selectedProperties, setSelectedProperties] = React.useState<ProductProperty[]>([]);
  const [productValue, setProductValue] = React.useState<Product>({});
  const { token } = React.useContext(AuthContext);
  const nav = useNavigate();
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 1) {
      handleSave();
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSave = async () => {
    try {
      const res = await createProduct({
        ...productValue,
        properties: selectedProperties
      }, token)
      if (res.isSuccess) {
        enqueueSnackbar('Ürün başarıyla kaydedildi', { variant: 'success' });
        nav('/stock');
      } else {
        enqueueSnackbar(res.message, { variant: 'error' });
      }
    } catch (err: any) {
      enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
      console.log(err)
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 164px)',
      width: '100%',
      maxWidth: 500,
    }}>
      <Box sx={{ flexGrow: 1 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 && <ManageProductInfo
          productValue={productValue}
          setProductValue={setProductValue}
          selectedProperties={selectedProperties}
          setSelectedProperties={setSelectedProperties}
        />}
        {activeStep === 1 && <ProductFutureForm
          selectedProperties={selectedProperties}
          setSelectedProperties={setSelectedProperties}
        />}
      </Box>

      {/* Navigation buttons fixed at the bottom */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
          mt: 2,
          borderTop: '1px solid #ddd', // Optional: adds a line above the buttons
        }}
      >
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mt: 1, mr: 1 }}
        >
          Geri
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ mt: 1, mr: 1 }}
          disabled={selectedProperties.length === 0}
        >
          {activeStep === steps.length - 1 ? 'Kaydet' : 'İleri'}
        </Button>
      </Box>
    </Box>
  );
}


// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import ManageProductInfo from './ManageProductInfo';
// import ProductFutureForm from './ProductFutureForm';

// const steps = ['Ürün Bilgileri', 'Ürün Özellikleri'];

// export default function AddEditProductStepper() {
//   const [activeStep, setActiveStep] = React.useState(0);
//   const [skipped, setSkipped] = React.useState(new Set<number>());

//   const isStepSkipped = (step: number) => {
//     return skipped.has(step);
//   };

//   const handleNext = () => {
//     let newSkipped = skipped;
//     if (isStepSkipped(activeStep)) {
//       newSkipped = new Set(newSkipped.values());
//       newSkipped.delete(activeStep);
//     }

//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     setSkipped(newSkipped);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };


//   return (
//     <Box sx={{
//       maxWidth: 500,
//       width: '100%',
//       height: '100%',
//     }}>
//       <Stepper activeStep={activeStep}>
//         {steps.map((label, index) => {
//           const stepProps: { completed?: boolean } = {};
//           const labelProps: {
//             optional?: React.ReactNode;
//           } = {};
//           if (isStepSkipped(index)) {
//             stepProps.completed = false;
//           }
//           return (
//             <Step key={label} {...stepProps}>
//               <StepLabel {...labelProps}>{label}</StepLabel>
//             </Step>
//           );
//         })}
//       </Stepper>
//       {activeStep === 0 && (
//         <ManageProductInfo />
//       )}
//       {activeStep === 1 && (
//         <ProductFutureForm />
//       )}
//       <Box
//         sx={{
//           flexGrow: 1,
//         }}
//       >
//       </Box>
//       <Box>
//         <Button
//           disabled={activeStep === 0}
//           onClick={handleBack}
//           sx={{ mt: 3, mr: 1 }}
//         >
//           Geri
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleNext}
//           sx={{ mt: 3, mr: 1 }}
//         >
//           {activeStep === steps.length - 1 ? 'Kaydet' : 'İleri'}
//         </Button>
//       </Box>
//     </Box>
//   );
// }
import { Box, FormControl, InputLabel, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { baseUrl } from '../utils/constants';
import CustomSelect from './common/CustomSelect';
import { AuthContext } from '../contexts/AuthProvider';

type UtilitySelectProps = {
  handleChange: (value: any) => void;
  value: any;
  meta: any
  required?: boolean;
}

export default function UtiltySelect({ handleChange, value, meta, required }: UtilitySelectProps) {

  const [opts, setOpts] = useState<any[]>([]);
  const [selectItems, setSelectItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);

  const fetchOpts = async (qs: string) => {
    try {
      setLoading(true);
      const res = await fetch(baseUrl + '/utilities-management' + qs,{
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      const data = await res.json();
      setOpts(data.data.slice(0, 200));
      setSelectItems(data.data.slice(0, 200).map((item: any) => ({ value: item.id, label: item.name })))
    } catch (err: any) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    let qs = '';

    if (meta.type === 'city') {
      qs = '/cities'
    } else if (meta.type === 'district') {
      qs = '/districts?cityId=' + meta.cityId
    } else if (meta.type === 'nationality') {
      qs = '/nationalities'
    } else if (meta.type === 'drivingLicence') {
      qs = '/driving-licences'
    } else if (meta.type === 'job') {
      qs = '/jobs'
    } else if (meta.type === 'user-type') {
      qs = '/user-types'
    } else if (meta.type === 'marital-status') {
      qs = '/marital-status-types'
    } else if (meta.type === 'gender') {
      qs = '/gender-types'
    } else if (meta.type === 'workingType') {
      qs = '/working-types'
    } else if (meta.type === 'country') {
      qs = '/countries'
    }

    fetchOpts(qs);

  }, [meta.cityId])

  return (
    <CustomSelect
      label={meta.label}
      items={selectItems}
      value={value}
      required={required}
      loading={loading}
      onSelect={(value) => { handleChange(value) }}
    />
  )
}

// export const getProducts = async (token: string) => {
//   const url = `${baseUrl}/product-supplier-management/product-stocks`;
//   const response = await fetch(url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${token}`
//     }
//   });

import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

//   const res = await handleResponse(response);

//   return res;
// }

export const addNewItemToCart = async (values: any, token: string) => {
  const url = `${baseUrl}/product-dealer-management/carts`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);

  return res;
}

export const updateItemInCart = async (values: any, token: string) => {
  const url = `${baseUrl}/product-dealer-management/carts`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);

  return res;
}

export const checkOut = async (values: any, token: string) => {
  const url = `${baseUrl}/product-dealer-management/payment`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(values)
  });

  const res = await handleResponse(response);

  return res;
}

import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import CustomBreadCrumbs from "../components/common/CustomBreadCrumbs";
import ShoppingCartMenu from "../components/ShoppingCartMenu";
import ShoppingCartList from "../components/ShoppingCartList";
import { AuthContext } from "../contexts/AuthProvider";
import useSWR, { mutate } from "swr";
import { Cart } from "../utils/types";
import { baseUrl } from "../utils/constants";
import { fetcher } from "../utils/globals";
import CustomButton from "../components/common/CustomButton";
import { checkOut } from "../services/CartServices";
import { enqueueSnackbar } from "notistack";

export default function ShoppingCartContainer() {

  const { token } = useContext(AuthContext);
  const theme = useTheme();

  const { data: cart, isLoading } = useSWR<{ data: Cart }>(
    `${baseUrl}/product-dealer-management/carts`,
    (url: string) => fetcher(url, token)
  );

  const handleCheckout = async () => {
    try {
      const res = await checkOut({ orderId: cart?.data.orderId }, token);
      if (res.isSuccess) {
        enqueueSnackbar("Siparişiniz başarıyla oluşturuldu", {
          variant: "success",
        });
        mutate(`${baseUrl}/product-dealer-management/carts`);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (err: any) {
      enqueueSnackbar("Bir hata oluştu", { variant: "error" });
      console.log(err);
    }
  }

  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: "Ana Sayfa", to: "/" },
          { label: "Market", to: "/market" },
          { label: "Sepetim" },
        ]}
      />
      <Box
        sx={{
          mt: 7,
        }}
      >
        <ShoppingCartList
          isLoading={isLoading}
          cart={cart?.data}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              display: "inline-block",
              padding: "7px 17px",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              fontSize: "16px",
              alignSelf: "flex-end",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
              }}
            >
              Toplam Fiyat: {cart?.data.totalPrice} ₺
            </Typography>
          </Box>
          <CustomButton
            sx={{
              padding: "7px 29px",
            }}
            label="Alışverişi Tamamla"
            variant="contained"
            onClick={handleCheckout}
          />
        </Box>

      </Box>
    </Box>
  );
}

import React, { useContext } from 'react';
import { ProductProperty } from '../utils/types';
import { baseUrl } from '../utils/constants';
import { AuthContext } from '../contexts/AuthProvider';
import { Autocomplete, TextField, Checkbox } from '@mui/material';

type PropertyAutocompleteProps = {
  value: ProductProperty[];
  onChange: (value: ProductProperty[]) => void;
};

export default function PropertyAutocomplete({ value, onChange }: PropertyAutocompleteProps) {

  const [properties, setProperties] = React.useState<ProductProperty[]>([]);
  const { token } = useContext(AuthContext);

  const fetchProperties = async () => {
    try {
      const qs = '/product-property-types';
      const res = await fetch(baseUrl + '/utilities-management' + qs, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      const data = await res.json();
      setProperties(data.data);
    } catch (err: any) {
      console.log(err)
    }
  }

  React.useEffect(() => {
    fetchProperties();
  }, []);

  return (
    <div>
      <Autocomplete
        multiple
        id="tags-standard"
        options={properties}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name || ''}
        defaultValue={value}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={selected}
              style={{ marginRight: 8 }}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Özellikler"
          />
        )}
      />
    </div>
  );
}

// export const getUserProfileInfo = async (userType: string, token: string) => {
//   const url = userType === 'dealers' ? `${baseUrl}/dealer-management/${userType}` : `${baseUrl}/supplier-management/${userType}`;
//   const response = await fetch(url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${token}`
//     }
//   });

import { create } from "domain";
import { Product } from "../utils/types";
import { baseUrl } from "../utils/constants";
import { handleResponse } from "./ResponseHandler";

//   const res = await handleResponse(response);

//   return res;
// }

export const getProducts = async (token: string) => {
  const url = `${baseUrl}/product-supplier-management/product-stocks`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  const res = await handleResponse(response);

  return res;
}


export const createProduct = async (product: Product, token: string) => {
  const url = `${baseUrl}/product-supplier-management/products`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(product)
  });

  const res = await handleResponse(response);

  return res;
}
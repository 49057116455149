import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function SocialButton() {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Button sx={{ color: "rgba(251, 255, 255, 1)", gap: 1 }}>
          <InstagramIcon />
          <Typography
            sx={{
              fontWeight: 400,
              maxWidth: 138,
              height: 24,
              fontSize: "17px",
              lineHeight: "24px",
              textTransform: "none",
            }}
          >
            @petabit.com.tr
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button sx={{ color: "rgba(251, 255, 255, 1)", gap: 1 }}>
          <YouTubeIcon />
          <Typography
            sx={{
              fontWeight: 400,
              maxWidth: 138,
              height: 24,
              fontSize: "17px",
              lineHeight: "24px",
              textTransform: "none",
            }}
          >
            @petabit.com.tr
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button sx={{ color: "rgba(251, 255, 255, 1)", gap: 1 }}>
          <TwitterIcon />
          <Typography
            sx={{
              fontWeight: 400,
              maxWidth: 138,
              height: 24,
              fontSize: "17px",
              lineHeight: "24px",
              textTransform: "none",
            }}
          >
            @petabit.com.tr
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

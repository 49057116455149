import { Box, Grid } from '@mui/material'
import React from 'react'
import CustomBreadCrumbs from '../components/common/CustomBreadCrumbs'
import ManageProductImages from '../components/ManageProductImages'
import AddEditProductStepper from '../components/AddEditProductStepper'

export default function AddEditProductContainer() {
  return (
    <Box>
      <CustomBreadCrumbs
        items={[{ label: "Ana Sayfa", to: "/" },
        { label: "Stok & Katalog", to: "/stock" },
        { label: "Yeni Ürün Ekle" }
        ]}
      />
      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <ManageProductImages />
        </Grid>

        <Grid item xs={12} md={6}>
          <AddEditProductStepper />
        </Grid>

      </Grid>
    </Box>
  )
}

import { Box, Button, Grid, Paper, Rating, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import protez from "../assets/159.png";
import group from "../assets/Group.png";
import b from "../assets/456.png";
import kol2 from "../assets/kol3.png";
import StarIcon from "@mui/icons-material/Star";
import CustomButton from "./common/CustomButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthProvider";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import { Product } from "../utils/types";

type ProductDetailComponentProps = {
  product: Product;
  handleAddToCart: (product: Product) => void;
};

export default function ProductDetailComponent({ product, handleAddToCart }: ProductDetailComponentProps) {

  const [selectedImage, setSelectedImage] = useState(protez);
  const [isImageVisible, setIsImageVisible] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { role } = useContext(AuthContext);
  const nav = useNavigate();

  const handleImageClick = (image: any) => {
    setIsImageVisible(false);
    setSelectedImage(image);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageTransitionEnd = () => {
    setIsImageVisible(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} lg={5}>
        <Box>
          <img
            style={{
              width: "100%",
              height: 465,
              objectFit: "contain",
              opacity: isImageVisible ? 1 : 0,
              transition: "opacity 0.005s, transform 0.5s",
              background: "#f0f0f0",
              borderRadius: 6,
              border: "2px solid rgba(211, 203, 251, 0.5)",
            }}
            src={selectedImage}
            alt=""
            onLoad={handleImageLoad}
            onTransitionEnd={handleImageTransitionEnd}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
              maxWidth: 98,
              gap: 2,
            }}
          >
            <img
              style={{
                width: "100%",
                height: 98,
                cursor: "pointer",
                border: "2px solid rgba(211, 203, 251, 1)",
                borderRadius: "4px",
                backgroundColor: "#f0f0f0",
              }}
              src={protez}
              alt=""
              onClick={() => handleImageClick(protez)}
            />
            <img
              style={{
                width: "100%",
                height: 98,
                cursor: "pointer",
                border: "2px solid rgba(211, 203, 251, 1)",
                borderRadius: "4px",
                backgroundColor: "#f0f0f0",
              }}
              src={kol2}
              alt=""
              onClick={() => handleImageClick(kol2)}
            />
            <img
              style={{
                width: "100%",
                height: 98,
                cursor: "pointer",
                border: "2px solid rgba(211, 203, 251, 1)",
                borderRadius: "4px",
                backgroundColor: "#f0f0f0",
              }}
              src={b}
              alt=""
              onClick={() => handleImageClick(b)}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item md={12} lg={7}>
        <Box
          sx={{
            height: '100%',
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              display: "flex",
              mb: 3,
            }}
          >
            <Rating
              name="rating"
              value={4.5}
              precision={0.5}
              emptyIcon={<StarIcon />}
              icon={<StarIcon />}
              readOnly
            />
            <Typography>({4.5})</Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "42px",
                lineHeight: "48px",
              }}
            >
              {product.name}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "21px",
                lineHeight: "24px",
                flexShrink: 1,
              }}
            >
              Firma ismi
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "17px",
                lineHeight: "24px",
                mt: 2,
              }}
            >
              {product.description}
            </Typography>
          </Box>
          {role === 'bayi' && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row", // Butonları yatay olarak sırala
                justifyContent: "flex-end",
                gap: 1,
                mt: 'auto' // Butonları altta tutmak için otomatik üst marjı kullan
              }}
            >
              <CustomButton
                label="Tedarikçi Detayları"
                preIcon={<InfoOutlinedIcon />}
                onClick={() => { }}
              />
              <CustomButton
                label="Sepete Ekle"
                variant="contained"
                preIcon={<LocalGroceryStoreOutlinedIcon />}
                onClick={() => { 
                  handleAddToCart(product);
                }}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

import { Settings } from "@mui/icons-material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import InfoIcon from "@mui/icons-material/Info";
import StarIcon from "@mui/icons-material/StarPurple500";
import { Avatar, Box, Menu, MenuItem } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import userPic from "../assets/user-pic.png";
import { AuthContext, RoleType } from "../contexts/AuthProvider";
import CustomButton from "./common/CustomButton";
import ShoppingButton from "./ShoppingButton";
import useSWR from "swr";
import { Cart } from "../utils/types";
import { baseUrl } from "../utils/constants";
import { fetcher } from "../utils/globals";

const drawerWidth = 230;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "transparent",
  zIndex: theme.zIndex.drawer - 1,
  width: `calc(100% - ${80}px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type MyAppBarProps = {
  open: boolean;
};

export default function MyAppBar({ open }: MyAppBarProps) {
  const theme = useTheme();
  const nav = useNavigate();
  const { role } = useContext(AuthContext);
  const { logout, token } = useContext(AuthContext);

  const { data: carts, isLoading } = useSWR<{ data: Cart }>(
    `${baseUrl}/product-dealer-management/carts`,
    (url: string) => fetcher(url, token)
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar elevation={0} position="fixed" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          padding: "0px 16px",
          height: "80px",
          boxShadow: "none",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#0F3F62",
              fontSize: "21px",
              fontWeight: 500,
            }}
          >
            Merhaba Umut Demir
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <DateRangeIcon sx={{ color: "#0F3F62", height: 20 }} />
            <Typography
              sx={{
                color: "#0F3F62",
                fontSize: "14px",
                fontWeight: 300,
              }}
            >
              3 Kasım 2022
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: 220,
            border: "2px solid #D3CBFB",
            borderRadius: "5px",
            justifyContent: "center",
          }}
        >
          {/* <Box
            sx={{
              width: "4px",
              height: "32px",
              backgroundColor: theme.palette.primary.main,
              borderRadius: "0px 2px 2px 0px",
            }}
          ></Box>
          <InfoIcon sx={{ color: theme.palette.primary.main, height: 24 }} /> */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: theme.palette.primary.main,
              fontSize: "14px",
            }}
          >
            ortezmarket.com
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <CustomButton
            sx={{ mr: 5 }}
            label="Kampanyalar"
            variant="contained"
            preIcon={<StarIcon sx={{ color: "#fff", height: 20 }} />}
            onClick={() => {
              nav("campaigns");
            }}
          />
          {role === 'bayi' && (
            <ShoppingButton
              cart={carts?.data}
            />
          )}
          <Box
            sx={{
              position: "relative",
            }}
          >
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => {
                handleClick(e);
              }}
            >
              <Avatar
                alt="Remy Sharp"
                src={userPic}
                sx={{ width: 40, height: 40 }}
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  logout();
                }}
              >
                Çıkış
              </MenuItem>
            </Menu>
          </Box>
          <CustomButton
            variant="contained"
            iconButton
            icon={<Settings sx={{ color: "#fff", height: 20 }} />}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

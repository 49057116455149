import { Button, Typography, Grid } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function TextButton() {
  return (
    <Grid
      container
      direction="column"
      spacing={3}
      sx={{
        mb: 10,
        ml: 5,
      }}
    >
      <Grid item>
        <Button sx={{ color: "rgba(251, 255, 255, 1)", gap: 2 }}>
          <PhoneIcon />
          <Typography
            sx={{
              maxWidth: 153,
              height: 24,
              fontWeight: 400,
              fontSize: "17px",
              lineHeight: "24px",
              alignItems: "center",
            }}
          >
            +90 583 589 14 56
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Button sx={{ color: "rgba(251, 255, 255, 1)", gap: 2 }}>
          <MailOutlineIcon />
          <Typography
            sx={{
              maxWidth: 169,
              height: 24,
              fontWeight: 400,
              fontSize: "17px",
              lineHeight: "24px",
              alignItems: "center",
              textTransform: "none",
            }}
          >
            info@petabit.com.tr
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(211, 203, 251, 1)",
    },
  },
});

export default function Text2() {
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
        position: "relative",
        minHeight: "200px",
      }}
      noValidate
      autoComplete="off"
    >
      <Box
        sx={{
          mb: 5,
        }}
      >
        <CustomTextField
          id="outlined-multiline-static"
          label="Mesajiniz..."
          multiline
          rows={4}
          sx={{
            maxWidth: 602,
          }}
        />
      </Box>
      <Button
        variant="contained"
        sx={{
          position: "absolute",
          right: "10px",
          textTransform: "none",
          mt: 2,
        }}
      >
        <Typography>Gönder</Typography>
      </Button>
    </Box>
  );
}

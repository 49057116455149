import { Box, Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import userPic from "../assets/user-pic.png";
import CustomButton from "./common/CustomButton";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";

export default function OrdersTableRowItem({ status }: { status: string }) {
  const nav = useNavigate();
  const loc = useLocation();
  const { role } = useContext(AuthContext);
  return (
    <TableRow
      sx={{
        "& td": {
          py: 0,
        },
      }}
    >
      <TableCell
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Typography style={{ fontSize: 12 }}>#394854</Typography>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img style={{ height: 24, width: 24 }} src={userPic} alt="user" />
          <Typography style={{ fontSize: 12 }}>Koray Okumuş</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography style={{ fontSize: 12 }}>Ürün İsmi</Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DateRangeOutlinedIcon style={{ fontSize: 16, marginRight: 4 }} />
          12.12.2021
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: 12,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color:
              status === "Yeni Sipariş"
                ? "#FF6565"
                : status === "Yolda"
                ? "#6DA8FF"
                : "#4AA785",
          }}
        >
          <FiberManualRecordIcon
            style={{
              fontSize: 12,
              marginRight: 4,
            }}
          />
          {status}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          width: 500,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            justifyContent: "flex-end",
            mx: 1,
            py: 0.5,
          }}
        >
          <CustomButton
            label="İrsaliye Görüntüle"
            variant="contained"
            size="small"
            onClick={() => {}}
          />
          <CustomButton
            label="Kargo Takip"
            variant="contained"
            size="small"
            onClick={() => {}}
          />
          {role === "bayi" ? (
            <CustomButton
              label="Fatura Görüntüle"
              variant="contained"
              size="small"
              onClick={() => {}}
            />
          ) : (
            <CustomButton
              label="Fatura Ekle"
              variant="contained"
              size="small"
              onClick={() => {}}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

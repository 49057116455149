import { Box } from '@mui/material'
import React from 'react'
import MyCustomersContainer from '../containers/MyCustomersContainer'

export default function MyCustomers() {
  return (
    <Box>
      <MyCustomersContainer />
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import ProfileContainer from '../containers/ProfileContainer'

export default function Profile() {
  return (
    <Box>
      <ProfileContainer />
    </Box>
  )
}

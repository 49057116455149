import { Box, IconButton, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import CustomTextInput from './common/CustomTextInput'
import CustomSelect from './common/CustomSelect'
import CustomMultiSelect from './common/CustomMultiSelect'
import RemoveIcon from '@mui/icons-material/Remove';
import { Add } from '@mui/icons-material'
import StockCounter from './StockCounter'
import AddEditProductStepper from './AddEditProductStepper'
import ProductSelect from './ProductSelect'
import { Product, ProductProperty } from '../utils/types'
import UtiltySelect from './UtiltySelect'
import PropertyAutocomplete from './PropertyAutocomplete'

type ManageProductInfoProps = {
  productValue: Product;
  setProductValue: React.Dispatch<React.SetStateAction<Product>>;
  selectedProperties: ProductProperty[];
  setSelectedProperties: React.Dispatch<React.SetStateAction<ProductProperty[]>>;
}

export default function ManageProductInfo({ productValue, setProductValue, selectedProperties, setSelectedProperties }: ManageProductInfoProps) {

  const metaMainCategory = useMemo(() => ({
    type: 'main-category',
    label: 'Kategori'
  }), []);

  const metaSubCategory = useMemo(() => ({
    type: 'sub-category',
    label: 'Alt Kategori',
    mainCategoryId: productValue.productMainCategoryId,
  }), [productValue.productMainCategoryId]);

  const metaCampaign = useMemo(() => ({
    type: 'campaign',
    label: 'Kampanya'
  }), []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mt: 3
      }}
    >
      <CustomTextInput
        label="Ürün Adı"
        value={productValue.name || ''}
        onChange={(e) => setProductValue({ ...productValue, name: e.target.value })}
        required
      />
      <CustomTextInput
        label="Ürün Açıklaması"
        value={productValue.description || ''}
        onChange={(e) => setProductValue({ ...productValue, description: e.target.value })}
        multiline
        rows={4}
        required
      />
      {/* <CustomSelect
        label="Grup"
        items={[
          { label: 'Protez', value: '1' },
          { label: 'Ortez', value: '2' },
        ]}
        value='1'
        onSelect={(value) => console.log(value)}
      /> */}
      <ProductSelect
        value={productValue.productMainCategoryId || null}
        handleChange={(value) => setProductValue({ ...productValue, productMainCategoryId: value })}
        meta={metaMainCategory}
      />
      <ProductSelect
        value={productValue.productSubCategoryId || null}
        handleChange={(value) => setProductValue({ ...productValue, productSubCategoryId: value })}
        meta={metaSubCategory}
      />
      <PropertyAutocomplete
        value={selectedProperties}
        onChange={(value) => setSelectedProperties(value)}
      />
      <ProductSelect
        value={productValue.campaniaId || null}
        handleChange={(value) => setProductValue({ ...productValue, campaniaId: value })}
        meta={metaCampaign}
      />
      <StockCounter
        value={productValue.stockQuantity || 0}
        onChange={(value) => setProductValue({ ...productValue, stockQuantity: value })}
      />
    </Box>
  )
}

import { Box } from "@mui/material";
import React from "react";
import CustomBreadCrumbs from "../components/common/CustomBreadCrumbs";
import StockTableAppBar from "../components/StockTableAppBar";
import StockTable from "../components/StockTable";
import { ProductStock } from "../utils/types";
import { AuthContext } from "../contexts/AuthProvider";
import { getProducts } from "../services/ProductServices";

export default function StockContainer() {

  const [stock, setStock] = React.useState<ProductStock[]>([]);
  const { token } = React.useContext(AuthContext);

  const fetchStock = async () => {
    const res = await getProducts(token);
    if (res.isSuccess) {
      setStock(res.data);
    }
  }

  React.useEffect(() => {
    fetchStock();
  }, [])

  return (
    <Box>
      <CustomBreadCrumbs
        items={[{ label: "Ana Sayfa", to: "/" }, { label: "Stok & Katalog" }]}
      />
      <StockTableAppBar />
      <Box
        sx={{
          mt: 2,
        }}
      >
        <StockTable
          stock={stock}
        />
      </Box>
    </Box>
  );
}

import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import CurrencyItem from './CurrencyItem'
import dolar from '../assets/dolar.png'
import euro from '../assets/euro.png'
import sterlin from '../assets/gbp.png'

export default function Currencies() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <CurrencyItem
            currencyName={
              <Typography
                sx={{
                  color:'text.secondary'
                }}
              >
                <strong>Dolar</strong> / USD
              </Typography>
            }
            price="18,0066₺"
            change={1.7}
            icon={dolar}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <CurrencyItem
            currencyName={
              <Typography
                sx={{
                  color:'text.secondary'
                }}
              >
                <strong>Euro</strong> / EUR
              </Typography>
            }
            price="21,0066₺"
            change={2.6}
            icon={euro}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <CurrencyItem
            currencyName={
              <Typography
                sx={{
                  color:'text.secondary'
                }}
              >
                <strong>Sterlin</strong> / GBP
              </Typography>
            }
            price="24,0066₺"
            change={4.7}
            icon={sterlin}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

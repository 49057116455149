import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Rating,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import productPic from "../assets/product-img.png";
import { CartDetail } from "../utils/types";

type ShoppingCartListItemProps = {
  product?: CartDetail;
};

export default function ShoppingCartListItem({ product }: ShoppingCartListItemProps) {
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const theme = useTheme();
  const handleQuantityChange = (amount: number) => {
    const newQuantity = quantity + amount;
    if (newQuantity >= 0) {
      setQuantity(newQuantity);
      setPrice(newQuantity * 100);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: 5,
      }}
    >
      <Box
        sx={{
          border: "1px solid #D3CBFB",
          borderRadius: "10px",
        }}
      >
        <img src={productPic} alt="" />
      </Box>
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          mt: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            position: "absolute",
          }}
        >
          <Rating
            name="read-only"
            value={4}
            size="small"
            getLabelText={(value: number) =>
              `${value} Heart${value !== 1 ? "s" : ""}`
            }
            readOnly
          />
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            4.0
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 5,
          }}
        >
          <Typography
            sx={{
              fontSize: "17px",
            }}
          >
            {product?.productName}
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
            }}
          >
            Firma Adı
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <ButtonGroup
            variant="contained"
            sx={{
              display: "flex",
              mt: 2,
              width: "173px",
              height: "40px",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
            }}
          >
            <IconButton
              aria-label="remove"
              onClick={() => handleQuantityChange(-1)}
              sx={{
                color: "#fff",
              }}
            >
              <RemoveIcon />
            </IconButton>
            <Typography variant="body1" sx={{ mx: 1 }}>
              {quantity}
            </Typography>
            <IconButton
              aria-label="add"
              onClick={() => handleQuantityChange(1)}
              sx={{
                color: "#fff",
              }}
            >
              <AddIcon />
            </IconButton>
          </ButtonGroup>
          <Box
            sx={{
              mt: 2,
              mb: 1,
              backgroundColor: theme.palette.primary.main,
              display: "inline-block",
              padding: "9px 20px",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              fontSize: "16px",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
              }}
            >
              Toplam Fiyat: {price} ₺
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import { Box } from '@mui/material'
import React from 'react'
import CustomTextInput from './common/CustomTextInput'
import { User } from '../utils/types';
import UtiltySelect from './UtiltySelect';

type ProfileDetailInfoProps = {
  user: User;
  onChange: (value: any) => void;
}

export default function ProfileDetailInfo({ user, onChange }: ProfileDetailInfoProps) {
  return (
    <Box>
      <CustomTextInput
        required
        label="Vergi Dairesi"
        value={user.taxOffice || ''}
        onChange={(e) => { onChange({ taxOffice: e.target.value }) }}
      />
      <CustomTextInput
        required
        label="Vergi No"
        value={user.taxNo || ''}
        onChange={(e) => { onChange({ taxNo: e.target.value }) }}
      />
      <CustomTextInput
        required
        label="Ünvan"
        value={user.tittle || ''}
        onChange={(e) => { onChange({ tittle: e.target.value }) }}
      />
      <UtiltySelect
        required
        handleChange={(value) => { onChange({ countryId: value, districtId: 0 }) }}
        value={user?.countryId || ''}
        meta={{ type: 'country', label: 'Ülke' }}
      />
      <UtiltySelect
        required
        handleChange={(value) => { onChange({ cityId: value, districtId: 0 }) }}
        value={user?.cityId || ''}
        meta={{ type: 'city', label: 'İl' }}
      />
      <CustomTextInput
        required
        label="Adres"
        multiline
        rows={4}
        value={user.address || ''}
        onChange={(e) => { onChange({ address: e.target.value }) }}
      />
    </Box>
  )
}

import { Box, Typography } from '@mui/material'
import { type } from 'os'

type CurrencyItemProps = {
  currencyName: React.ReactNode
  price: string
  change: number
  icon: string
}

export default function CurrencyItem({ currencyName, price, change, icon }: CurrencyItemProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 2,
        background: 'white',
        borderRadius: '16px',
        border: '1px solid #D3CBFB',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          width: '100%'
        }}
      >
        <img src={icon} alt="icon" />
        {currencyName}
        <Typography
          fontWeight={700}
        >
          {price}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: change > 0 ? '#00B341' : '#FF0000'
        }}
      >
        {change}%
      </Typography>
    </Box>
  )
}

import { Box } from '@mui/material'
import React from 'react'
import CustomBreadCrumbs from '../components/common/CustomBreadCrumbs'
import OrdersTableAppBar from '../components/OrdersTableAppBar'
import OrdersTable from '../components/OrdersTable'

export default function OrdersContainer() {
  return (
    <Box>
      <CustomBreadCrumbs
        items={[
          { label: 'Ana Sayfa', to: '/' },
          { label: 'Siparişlerim' },
        ]}
      />
      <OrdersTableAppBar />
      <Box
        sx={{
          mt: 2
        }}
      >
        <OrdersTable />
      </Box>
    </Box>
  )
}

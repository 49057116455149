import { Box, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import OrdersTableRowItem from './OrdersTableRowItem';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthProvider';

export default function OrdersTable() {

  const { role } = useContext(AuthContext);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
          background: 'white',
          borderRadius: 2,
          border: '1px solid #D3CBFB',
          width: '100%'
        }}
      >
        <TableContainer
          style={{
            overflowX: 'initial',
          }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead
              sx={{
                '& th': {
                  backgroundColor: 'transparent !important'
                }
              }}
            >
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontSize: 12 }}>Sipariş Id</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: 12 }}>
                    {role === 'bayi' ? 'Tedarikçi' : 'Bayi'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: 12 }}>Ürün</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: 12 }}>Tarih</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontSize: 12 }}>Durum</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography sx={{ fontSize: 12 }}>İşlemler</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                  <OrdersTableRowItem
                    key={index}
                    status={index % 3 === 0 ? 'Yeni Sipariş' : index % 2 === 0 ? 'Yolda' : 'Tamamlandı'}
                  />
                ))
              }
            </TableBody>
          </Table>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 10,
              width: '100%',
            }}
          >
          </Box>
        </TableContainer>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mt: 1
        }}
      >
        <Pagination count={10} size="small" />
      </Box>
    </Box>
  )
}

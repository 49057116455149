import { Box, FormControl, InputLabel, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { baseUrl } from '../utils/constants';
import CustomSelect from './common/CustomSelect';
import { AuthContext } from '../contexts/AuthProvider';

type ProductSelectProps = {
  handleChange: (value: any) => void;
  value: any;
  meta: any
  required?: boolean;
}

export default function ProductSelect({ handleChange, value, meta, required }: ProductSelectProps) {

  const [opts, setOpts] = useState<any[]>([]);
  const [selectItems, setSelectItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);

  const fetchOpts = async (qs: string) => {
    try {
      setLoading(true);
      const res = await fetch(baseUrl + '/product-management' + qs, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      const data = await res.json();
      setOpts(data.data.slice(0, 200));
      setSelectItems(data.data.slice(0, 200).map((item: any) => ({ value: item.id, label: item.name })))
    } catch (err: any) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    let qs = '';

    if (meta.type === 'main-category') {
      qs = '/main-categories'
    } else if (meta.type === 'sub-category') {
      qs = '/sub-categories/' + meta.mainCategoryId
    } else if (meta.type === 'campaign') {
      qs = '/campaigns'
    }

    fetchOpts(qs);

  }, [meta])

  return (
    <CustomSelect
      label={meta.label}
      items={selectItems}
      value={value}
      required={required}
      loading={loading}
      onSelect={(value) => { handleChange(value) }}
    />
  )
}

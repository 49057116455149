import React from "react";
import ShoppingCartListItem from "./ShoppingCartListItem";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import productPic from "../assets/product-img.png";
import CustomButton from "./common/CustomButton";
import { Cart } from "../utils/types";

type ShoppingCartListProps = {
  isLoading?: boolean;
  cart?: Cart
};

export default function ShoppingCartList({ isLoading, cart }: ShoppingCartListProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        gap: 3,
      }}
    >
      {
        cart?.cartDetail.map((item) => (
          <>
            <ShoppingCartListItem
              product={item}
            />
            <Divider />
          </>
        ))
      }
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "20px",
          mt: 2,
        }}
      >
      </Box>
    </Box>
  );
}
